import { useCallback, useContext, useEffect, useReducer } from "react";
import { FormattedMessage } from "react-intl";
import dashboardsEmptyState from "../../../images/dashboards-empty-state.svg";
import { Maybe, Site } from "../../../types.g";
import { SiteContext } from "../sitecontext";
import { SiteRecorderAction } from "./actions/models";
import { ActionsControl } from "./actionscontrol";
import Layout from "./layout";
import { NavigationControl } from "./navigationcontrol";
import { InitialRecorderState, RecorderReducer } from "./reducers";
import { SiteFrame } from "./siteframe";
import { DashboardsEmptyState } from "./styles";

export interface SiteRecorderProps {
  onSiteConfigurationClick: () => void;
  site?: Maybe<Partial<Site>>;
  onRecordingUpdated: (actions: SiteRecorderAction[]) => void;
  setDashboardTitle: (string) => void;
  dashboardTitle: string;
  setExtensionVersion: (string) => void;
}

export const SiteRecorder = (props: SiteRecorderProps) => {
  const [state, dispatch] = useReducer(RecorderReducer, {
    ...InitialRecorderState,
    url: props.site?.url || "",
    dashboardTitle: props.dashboardTitle,
  });
  const siteContext = useContext(SiteContext);

  useEffect(() => {
    props.onRecordingUpdated(state.actions);
  }, [state.actions]);

  useEffect(() => {
    if (props.site) {
      siteContext.setIsPreview();
    }
  }, [props.site]);

  useEffect(() => {
    // Update to take locale into account
    const defaultDashboardTitle = "Dashboard";
    if (props.dashboardTitle === defaultDashboardTitle) {
      props.setDashboardTitle(state.dashboardTitle);
    }
  }, [state.dashboardTitle]);

  useEffect(() => {
    props.setExtensionVersion(state.extensionVersion);
  }, [state.extensionVersion]);

  const navigationHandler = useCallback(() => {
    console.log("navigated");
  }, [state]);

  return (
    <Layout>
      <Layout.Navigator>
        <NavigationControl
          url={state.url}
          recorderUrl={state.recorderUrl}
          mode={state.navigationMode}
          frameWidth={state.frameWidthType}
          dispatch={dispatch}
          onNavigate={navigationHandler}
          isRecording={state.isRecording}
          hasActions={state.actions.length > 1} // Manage Session will now always be present, meaning state.actions will always have at least 1 action, so we need to check if there are any additional actions
        />
      </Layout.Navigator>
      <Layout.Controls visible={state.url}>
        <ActionsControl
          actions={state.actions}
          isSelectingElement={state.isSelectingElement}
          isRecording={state.isRecording}
          selectedElement={state.selectedElement}
          editorType={state.editorType}
          editorAction={state.editorAction}
          dispatch={dispatch}
        />
      </Layout.Controls>
      <Layout.Pane visible={state.url}>
        <SiteFrame
          url={state.url}
          mode={state.navigationMode}
          isRecording={state.isRecording}
          isSelectingElement={state.isSelectingElement}
          selectedElement={state.selectedElement}
          editorType={state.editorType}
          editorAction={state.editorAction}
          dispatch={dispatch}
          actions={state.actions}
          hasOtc={state.hasOtc}
          navigateCount={state.navigateCount}
          site={props.site}
        />
      </Layout.Pane>
      {state.actions.length <= 1 && !state.url && (
        <DashboardsEmptyState>
          <img src={dashboardsEmptyState} />
          <div className="tips">
            <h2>💡 Protips</h2>
            <h3>
              <FormattedMessage
                id="ui_component.site.recorder.pro_tip_1"
                defaultMessage="Always use the final preview link, not the homepage or login page. This bypasses potential changes in the dashboard platform, preventing disruptions to your action flow."
              />
            </h3>
          </div>
        </DashboardsEmptyState>
      )}
    </Layout>
  );
};
