import { Theme } from "@screencloud/screencloud-ui-components";
import styled from "styled-components";

export const LayoutContainer = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  grid-template-rows: 0.1fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .actions-grid-section {
    grid-area: 1 / 1 / 2 / 2;
    border-bottom: 1px solid ${Theme.color.lightGrey};
    border-right: 1px solid ${Theme.color.lightGrey};
    background-color: ${Theme.color.white};
    display: flex;

    .overall-actions {
      width: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      background-color: ${Theme.color.white};
      justify-content: center;
      font-weight: bold;

      &.header {
        font-weight: bold;
      }

      button,
      button:active {
        height: 30px;
        font-size: 14px;

        &.spaced {
          margin-top: 10px;
        }
      }
    }
  }

  .navigator-grid-section {
    grid-area: 1 / 1 / 2 / 3;
    border-bottom: 1px solid ${Theme.color.lightGrey};
    background-color: ${Theme.color.white};
    display: flex;

    > div {
      padding: 10px 20px;
    }

    .url-label {
      font-weight: bold;
    }

    .url-input {
      input {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: 0px;
      }
    }
  }

  .controls-grid-section {
    grid-area: 2 / 1 / 3 / 2;
    background-color: ${Theme.color.white};
    display: flex;
    overflow: hidden;
    margin: 24px;

    &.visible {
      visibility: visible;
    }

    &.hidden {
      visibility: hidden;
    }
  }

  .pane-grid-section {
    grid-area: 2 / 2 / 3 / 3;
    display: flex;

    &.visible {
      visibility: visible;
    }

    &.hidden {
      visibility: hidden;
    }
  }

  .configuration-grid-section {
    grid-area: 3 / 1 / 3 / 2;
    border-top: 1px solid ${Theme.color.lightGrey};
    border-right: 1px solid ${Theme.color.lightGrey};
    background-color: ${Theme.color.white};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ConfigurationControlPanel = styled.div`
  padding: 10px;

  .compute-options {
    border: 1px solid ${Theme.color.lightGrey};
    font-weight: bold;
    text-align: center;
    width: 100px !important;
    padding: 10px;
    font-size: 0.875em;
  }
`;

export const RecordingControlPanel = styled.div`
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .recording {
    background-color: ${Theme.color.red};
    color: ${Theme.color.white};

    &:hover {
      background-color: ${Theme.color.redHover};
      color: ${Theme.color.white};
    }

    &:focus {
      background-color: ${Theme.color.redHover};
      color: ${Theme.color.white};
    }

    &-icon {
      color: ${Theme.color.white}!important;
      background-color: ${Theme.color.white}!important;
    }
  }
`;

export const NavigationControlPanel = styled.div`
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: end;

  .url {
    flex-grow: 1;
  }

  .url-options {
    width: 150px !important;
  }

  .controls {
    height: 76%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .button {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  .width-options {
    margin-right: 20px;
    width: 120px !important;
  }
`;

export const ActionsControlPanel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${Theme.color.nearWhite};

  .separator {
    width: calc(100% - 40px);
    margin: 20px auto;
    height: 1px;
    background-color: #ccc;
  }

  .controls {
    border-bottom: 1px solid ${Theme.color.lightGrey};
    padding-top: 20px;

    .panel {
      padding-left: 20px;
      padding-right: 20px;
    }

    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
    }

    .action-buttons {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .btn {
        margin-right: 8px;
        margin-bottom: 8px;

        &.full-width {
          width: 100%;
        }
      }
    }
  }

  .actions,
  .overall-actions {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    button,
    button:active {
      height: 30px;
      font-size: 14px;

      &.spaced {
        margin-top: 10px;
      }
    }

    .clear-actions {
      padding: 20px;
    }

    .panel {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
    }

    .actions-header {
      font-weight: bold;
    }

    .header {
      display: flex;
      justify-content: flex-start;
    }

    .footer {
      display: flex;
      justify-content: center;
      border-top: 1px solid ${Theme.color.lightGrey};
      padding-top: 20px;

      .button {
        background-color: var(--color-primary-background);
      }
    }
  }

  .overall-actions {
    padding: 20px;
    background-color: ${Theme.color.white};
    border-bottom: 1px solid ${Theme.color.lightGrey};
  }
`;

export const SiteFramePanel = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;

  :empty {
    :before {
      content: "Enter a url to start";
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${Theme.color.darkGrey};
    }
  }

  .frame {
    flex-grow: 1;
    border: none;
  }

  .popupMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      margin-top: 16px;
    }
  }

  .url-blocked-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .url-blocked-message {
    margin-bottom: 16px;
  }

  .sidePanel > span {
    padding: 24px 15px;
    ${Theme.default.borderRadius};
    background-color: ${Theme.color.silver};
  }
`;

export const DashboardsEmptyState = styled.div`
  grid-area: 2 / 1 / 2 / 3;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    height: 35%;
  }

  .tips {
    text-align: center;
    width: 25%;
    margin: 0px auto;

    h3 {
      color: var(--color-subtle, #7d7e84);
      font-size: var(--font-size-md, 16px);
      font-style: normal;
      font-weight: 400;
    }
  }
`;

export const ActionListContainer = styled.div`
  overflow-y: auto;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &.pointer {
    cursor: pointer;
  }

  &.shadow {
    .container > .details > div {
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
      box-shadow: 0px -4px 0px 0px rgba(0, 0, 0, 0.08) inset;
    }
  }

  .index {
    flex: 0 40px;
    text-align: center;
    color: ${Theme.color.grey};
  }

  .container {
    overflow: hidden;

    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 56px;
    word-break: break-word;

    .settings-control,
    .delete-control {
      display: none;
    }

    &:hover {
      .settings-control,
      .delete-control {
        display: block;
      }
    }

    .icon-container {
      display: flex;
      background-color: #fff4ed;
      margin: 5px;
      border-radius: 8px;

      &.link {
        * {
          background-color: var(--color-red-500);
        }
        background-color: var(--color-red-50);
      }

      &.camera {
        * {
          background: var(--color-green-800, rgba(27, 96, 49, 1));
        }
        background-color: var(--color-green-50);
      }

      &.screen-guide {
        * {
          background: var(--color-yellow-800, rgba(146, 116, 0, 1));
        }
        background-color: var(--color-yellow-50);
      }

      &.user {
        * {
          background: var(--color-torquise-600, rgba(46, 110, 115, 1));
        }
        background-color: var(--color-green-50);
      }

      &.lock {
        * {
          background: var(--color-gray-800, rgba(54, 54, 57, 1));
        }
        background-color: var(--color-blue-50);
      }
    }

    .icon {
      width: 26px;
      height: 26px;
      margin: 5px;
    }

    .drag-control {
      background-color: ${Theme.color.silver};
      cursor: pointer;

      flex: 0 32px;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        margin-left: 4px;
      }

      &:hover {
        cursor: grab;
      }
    }

    .details {
      flex: 1;
    }

    &.vertical-link {
      & .details {
        &::after {
          content: "";
          background-color: var(--color-gray-800);
          position: absolute;
          width: 2px;
          height: var(--previous-action-connector, 8px);
          top: -8px;
          left: 50%;
          display: block;
        }
      }
    }

    .settings-control,
    .delete-control {
      .icon {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin: auto;
        &:hover {
          background-color: ${Theme.color.grey};
        }
      }
    }

    .settings-control {
      position: absolute;
      right: 10%;
      top: 20%;
    }

    .delete-control {
      cursor: pointer;
      flex: 0 32px;
      align-self: stretch;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 5%;
      top: 25%;

      .icon {
        margin-left: 3px;
      }
    }

    .action-end {
      flex: 0 32px;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const ActionEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: var(--color-gray-50, rgba(246, 246, 247, 1));
  border-radius: 0px 0px 16px 16px;

  h2 {
    font-size: 16px;
  }

  .action-info-text {
    padding-bottom: 16px;
  }

  .action-button-container {
    display: flex;
    width: 20%;
    gap: 10px;
    justify-content: end;
    margin-left: auto;
  }

  .editor-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .field-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;

      .field-name {
        flex: 1;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.36;
      }
    }

    .label {
      width: 100px !important;
    }

    .mouse-left-click,
    .mouse-right-click,
    .mouse-middle-click {
      &.active {
        background: var(--color-gray-950, rgba(25, 25, 27, 1));
      }
    }
  }

  .button-options {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: end;
    gap: 10px;
  }

  .editor-button,
  .editor-inline-button {
    font-size: 14px;
    line-height: 1.5;
    min-width: 100px;
  }

  .snapshot-label {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-gray-400, rgba(125, 126, 132, 1));
  }

  .editor-button {
    margin: 0 0 8px 0 !important;
  }

  .editor-button.help-button {
    margin: 8px 0 8px 0 !important;
  }

  .help-strip {
    border: 1px solid ${Theme.color.lightGrey};
    width: 99%;
  }
`;

export const LoopControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;

  .index {
    flex: 0 40px;
    font-weight: bold;
    text-align: center;
  }

  .container {
    background-color: var(--color-primary-background);
    border: 1px solid var(--color-primary-background);
    ${Theme.default.borderRadius};
    overflow: hidden;

    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 56px;
    word-break: break-word;

    .drag-control {
      background-color: var(--color-primary-background);
      cursor: pointer;

      flex: 0 32px;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        margin-left: 4px;
        background-color: #444;
      }

      &:hover {
        cursor: grab;
      }
    }

    .details {
      flex: 1;
      padding: 10px;
      display: flex;
      flex-direction: column;

      font-size: 14px;
      line-height: 1.4;
      font-weight: bold;
      cursor: pointer;

      .detail {
        font-weight: normal;
        margin-top: 2px;
      }
    }

    .delete-control {
      background-color: var(--color-primary-background);
      cursor: pointer;

      flex: 0 32px;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        margin-left: 3px;
        background-color: #444;
      }

      &:hover {
        background-color: ${Theme.color.redHover};

        .icon {
          background-color: ${Theme.color.white};
        }
      }
    }
  }
`;

export const EditModeContainer = styled.div`
  --edit-border: 0px;
  --child-border-radius: 16px 16px 0px 0px;
  --previous-action-connector: 0px;
  border-radius: 16px;
  margin-bottom: 8px;

  &.browser-click {
    border: 2px solid var(--color-yellow-400, rgba(255, 209, 12, 1));
  }
  &.renderer-render-single {
    border: 2px solid var(--color-green-500, rgba(42, 181, 86, 1));
  }
  &.browser-send-username {
    border: 2px solid var(--color-torquise-500, rgba(62, 146, 150, 1));
  }
  &.browser-send-password {
    border: 2px solid var(--color-blue-500, rgba(33, 154, 255, 1));
  }
`;

export const ActionDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 8px;
  align-items: center;
  border-radius: 16px;
  border: var(--edit-border, 1px solid ${Theme.color.lightGrey});
  border-radius: var(--child-border-radius, 16px);
  border-bottom: 1px solid var(--color-black-10, rgba(0, 0, 0, 0.1));

  margin-bottom: 8px;
  background-color: white;
  line-height: 1.4;

  .icon {
    margin-right: 8px;
  }

  .details {
    display: flex;
    flex-direction: column;

    .name {
      font-size: 16px;

      &.inline {
        display: flex;
        justify-content: center;
        font-weight: bold;
        font-size: var(--font-size-md, 16px);

        div {
          margin: auto 0px;
        }
      }
    }

    .description {
      color: #888;
      margin-top: 2px;
      font-size: 12px;
    }
  }
`;

interface DescriptionProps {
  isErrorTypeAndDescription: boolean;
}

export const Description = styled.span<DescriptionProps>`
  color: ${(props) =>
    props.isErrorTypeAndDescription ? Theme.color.error : "initial"};
`;

export const PreviewSubMessage = styled.div`
  margin-bottom: 10px;
  padding: 13px 8px;
  border: 1px solid ${Theme.color.borderSection};
  ${Theme.default.borderRadius};
  display: flex;
  align-items: center;
  background-color: ${Theme.color.nearWhite};

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    bottom: 86px;
  }

  * {
    margin-right: 8px;
  }

  @media screen and (max-width: ${Theme.break.small}) {
    margin: 0 20px;
  }
`;

export const ActionsChangeInfo = styled.div`
  margin-bottom: 10px;
  margin-top: 8px;
  padding: 13px 8px;
  border: 1px solid var(--color-blue-500);
  ${Theme.default.borderRadius};
  display: grid;
  grid-template-columns: 5% 95%;
  background-color: var(--color-blue-100);
  color: var(--color-blue-800);
  font-size: var(--font-size-sm, 14px);

  i {
    background-color: var(--color-blue-600);
  }

  @media screen and (max-width: ${Theme.break.mediumAlt}) {
    bottom: 86px;
  }

  * {
    margin-right: 8px;
  }

  @media screen and (max-width: ${Theme.break.small}) {
    margin: 0 20px;
  }
`;

export const DashboardPopoverHeader = styled.div`
  margin: 14px 8px;
`;

export const DashboardPopoverContent = styled.div`
  margin: 14px 8px 10px;

  div.dismiss {
    margin-top: 14px;
    text-align: right;

    a {
      color: ${Theme.color.lightGrey};
      cursor: pointer;

      &:hover {
        color: ${Theme.color.white};
      }
    }
  }
`;
